<template>
	<div class = "content">
		<div v-if = "hasHeader" class =" logohedder w-100 d-flex">
			<slot name =  "header">
              <router-link class ="m-auto" :to="hasLink">
                  <img width="32" height="32" src="@/static/img/Logomark/FloweLogomark_Blue_S.png" class ="mr-3">
                  <img height="25" class ="my-auto" src="@/static/img/Logotype/FloweLogotype_Blue_M.png" >
              </router-link>
          	</slot>
        </div>

        <div v-if = "hasHeader" class ="diverder-middel "></div>

        <template v-for="item in userOrgItems">
        	<slot name =  "orgnazations">
	            <a class="d-flex align-items-center statusopts"  :key="item._id">
	                <div class="d-flex flex-row w-100" >
	                	<base-icon-label class =" mr-auto overflow-string dropdown-item notfontwight" iconSize ="sm" v-if = "item.key == orgkey && baseIconLabel" icon ="far fa-check" forcesm :labelafter ="item.name" :class="{'disabled':noselect}" :badge="item.status == 'disabled' ? 'Inactive' : ''" /> 
                      	<base-icon-label v-else-if ="baseIconLabel" class =" mr-auto  dropdown-item notfontwight" iconSize ="sm" @click.prevent = "selectorg(item)" icon ="" forcesm :labelafter ="item.name" :class="{'disabled':noselect}" :badge="item.status == 'disabled' ? 'Inactive' : ''"/>

		                <base-org-logo v-else @click = "selectorg(item)" :organization = "item" class="h-48px" :inlist = "true" :current = "item.key == orgkey " />

		                <base-icon v-if = "item.key == orgkey" @click.prevent= "toorgsettings(item)" iconSize ="sm" class = "notfontwight dropdown-item overflow-string ml-auto " icon ="far fa-angle-double-right" :class="{'disabled':noselect}"/>
	             	</div>
	            </a>
        	</slot>
        </template>
        <template v-for="element in buttomelement">
        	<slot name =  "orgnazations">
        		<div :key = "element._id">
	        		<div class ="diverder-middel"></div>
	        		<a class="d-flex align-items-center statusopts">
	                  	<div class ="d-flex w-100">
	                    	<base-icon-label :iconSize = "element.iconSize"  @click="action(element.action)" :class = "buttomelementclasses" :icon ="element.icon" :labelafter ="element.labelafter"/>
	                  	</div>
	                </a>
	            </div>
    		</slot>
    	</template>

	</div>
</template>

<script type="text/javascript"></script>
<script>
	import {bus} from '@/main'
	import Organizationsapi from '@/services/api/organizations'


	export default{
		name: 'orgnazationsSelector',
		props:{
			orgkey:{
				type:String,
				default: ""
			},
			noselect:{
				type:Boolean,
				default:false
			},
			hasHeader:{
				type: Boolean,
				default:true
			},
			hasLink:{
				type: String,
				default:"/"
			},
			userOrgItems:{
				type: Array,
				default: () => []
			},
			baseIconLabel:{
				type: Boolean,
				default: false
			},
			defaultElement:{
				type: Boolean,
				default: true,
			},

			buttomElement:{
				type: Array,
				default:() => []
			},
			buttomElementPos:{
				type:Number,
				default:0
			},
			defaultButtomElementClasses:{
				type:String,
				default: "clickable notfontwight"
			},
			buttomElementClasses:{
				type:String,
				default:""
			},
			hasProfileElement:{
				type:Boolean,
				default:true			
			}

		},

		data(){
			return{
				defaultelement:[
					{
						iconSize:"sm",labelbefore:"", labelafter: "View all organizations", icon:"",
						action: "showallorganizations"
					},
					{ 
						iconSize:"sm",labelbefore:"", labelafter: "Create new organization", icon:"far fa-plus",
						action: "showorgsettings"
					},
					
				],
				profileelement:[
					{
						iconSize:"sm",labelbefore:"", labelafter: "My profile", icon:"",
						action: "openprofile"
					},
					{
						iconSize:"sm",labelbefore:"", labelafter: "Sign out", icon:"far fa-sign-out",
						action: "signout"
					},
				]
			}
		},

		computed: {

			buttomelementclasses(){
				let classes = this.defaultButtomElementClasses
				classes += `${this.buttomElementClasses}`
				return classes
			},

			buttomelement(){
				let element = this.defaultelement
				if (this.hasProfileElement){
					element = element.concat(this.profileelement)
				}
				return element
			},

			selectedorg(){
		        return  this.$store.state.organizations.currentorganization
		    },

		},

		methods:{
			action(func){
				if (func == "showorgsettings"){
					this.showorgsettings()
				} else if (func == "showallorganizations"){
					this.showallorganizations()

				} else if (func == "openprofile"){
					this.openprofile()
				} else if (func == "signout"){
					this.signout()
				} else {
					this.$emit(`${func}`)
				}
				
			},

			signout(){
				let self = this
				this.$store.dispatch('doSignOut')
			},

			showorgsettings(){
				this.$root.$emit('bv::hide::modal', 'narbarmenu')
				this.$emit('showorgsettings')
			},

			openprofile(){
				this.$root.$emit('bv::hide::modal', 'narbarmenu')
				this.$router.push('/' + this.orgkey + '/profile')
			},

			showallorganizations(){
				//console.log( this.$route.name , "xxxxx")

				this.$root.$emit('bv::hide::modal', 'narbarmenu')

				if( this.$route.name != "organizationsoverview"){
					this.$router.push( '/' + this.orgkey + '/organizations' )
				}else{
					 bus.$emit('refreshorgpage')
				}
				
				
				
				
			},

			selectorg(org){


				//setTimeout( function(){

				//	$('#mainsidebar').width("60")
					//alert('')
				//}, 100)

				
				 //this.$root.$emit('bv::hide::modal', 'narbarmenu')


			    if( this.selectedorg._id != org._id){

			    	let self = this

			    	Organizationsapi.Details( localStorage.token, org.key ).then( function(response){

			    		//console.log( response.data.organization , "xxxxxxv")

			    		if( response.data.organization && response.data.organization.withaccess && response.data.organization.withaccess == true ){

				    		self.$store.dispatch('setCurrentOrg',{ organization:org, token:localStorage.token }).then((response)=>{
				    			

				    				var str1 = 'Changed organization'
							        var str = 'You are now signed in to the ' + org.name + ' organization.'

						    		bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:false, sup:''})

						    		self.$router.push( '/'+org.key+'/boards?select' )
							      self.$emit('close')


							      self.$cookie.set('showorghelp', "yes");
							      bus.$emit('showorghelp')
				    			

				    		})

			    		}else{

			    			var str1 = 'Failed to change organization'
							var str = 'You do not have access to this organization.'

			    			bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:false, sup:'', callback:function(){
						    			location.reload()
						    }})
			    		}
			    		/*alert( response.myrole )

			    		if( response.myrole && response.myrole != 'DISABLE' ){

			    			
				        	

			    		}else{

			    			//var str1 = 'Permission denied'
				        	//var str = 'You are not authorized to access ' + org.name + ' organization.'

			    			//bus.$emit('craeteaToast', {content:str,title:str1, variant:'danger',append:false, sup:''})


			    		}*/

			    		


			        	

				       
			        })
			        

			        
			    }
			},

			toorgsettings(org){

			    this.$root.$emit('bv::hide::modal', 'narbarmenu')
			    this.$store.dispatch('setCurrentOrg',{ organization:org })
			    this.$router.push( '/'+ org.key +'/organizations/about' )

			},
		}
	}
</script>