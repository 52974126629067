import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl


export default {

	// get a newsletter feed by user id

	Getfeed( token, context ){
		//console.log('https://capi.flowe.io/Rest/Newsletter?act=getall&auth='+userid)
		return axios.get(  '/Rest/Newsletter?act=getall&t='+token+'&context='+context )
	},

	Getnewsletter( payload ){
		console.log(payload)
		//console.log('https://capi.flowe.io/Rest/Newsletter?act=get&newsletterid='+payload._nid+'&auth='+payload._uid )
		return axios.get(  '/Rest/Newsletter?act=get&newsletterid='+payload._nid+'&auth='+payload._uid )
	},

	Getnewsletterbyid( newsletterid, token ){

		//console.log('https://capi.flowe.io/Rest/Newsletter?act=get&newsletterid='+payload._nid+'&auth='+payload._uid )
		return axios.get(  '/Rest/Newsletter?act=get&newsletterid='+newsletterid+'&t='+token )
	},

	Getlist( ispublished, cockpitid, token ){
		///
		return axios.get( 'Newsletter/GetAll/?published=' + ispublished + '&cid=' + cockpitid + '&t=' + token )
	},

	Getlistsorted( ispublished, cockpitid, token, sortby, sortdir ){
		/*

		ispublished : bool | if it is published or not
		cockpitid : Objectid | id of cockpit 
		token : token | user token
		sortby : int | sortby the column number from table
		sortdir : enum | asc,desc

		*/


		return axios.get( 'Newsletter/GetAll/?published=' + ispublished + '&sortby' + sortby + '&sortdir=' + sortdir+ '&cid=' + cockpitid + '&t=' + token )
	},


	Save( payload ){
		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Newsletter/Save', URLparams.toString() )
	},

	Delete( newsletterid, token  ){
		return axios.get( '/Newsletter/Delete/?newsletterid=' + newsletterid + '&t=' + token  )
	},

	Openforviewing( newsletterid, token, origin, countview ){
		return axios.get( '/Newsletter/Get/?openedforviewing=1&newsletterid=' + newsletterid + '&t=' + token + '&origin=' + origin + '&countview=' + countview )
	},
	
	Openforedit( newsletterid, token){
		return axios.get( '/Newsletter/Get/?openedforediting=1&newsletterid=' + newsletterid + '&t=' + token )
	},

	Publish( newsletterid, token){
		return axios.get( '/Newsletter/Publish/?newsletterid=' + newsletterid + '&t=' + token )
	},

	Unlock( newsletterid, token){
		return axios.get( '/Newsletter/Unlock/?newsletterid=' + newsletterid + '&t=' + token )
	}


}
