import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

export default {

	Updateuserstatus( userid, orgkey, action ){
		
		//https://capi.flowe.io/Users/Details?token=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl

		var toaction = ""

		if( action ){
			toaction = action
		}
		
		return axios.get(  '/Rest/Updateuserstatus?userid=' + userid + '&context=' + orgkey + '&action='+toaction )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Updateteamuserstatus( teamid, orgkey ){
		
		//https://capi.flowe.io/Users/Details?token=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl
		
		return axios.get(  '/Rest/Updateteamstatus?teamid=' + teamid + '&context=' + orgkey )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Deleteteam( token, teamid, confirm, orgkey ){
		
		//https://capi.flowe.io/Users/Details?token=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl

		return axios.get(  '/Rest/Teams?action=delete&t=' + token + '&teamid=' + teamid + '&confirm=' + confirm + '&context=' + orgkey )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Details( token, key ){
		
		//https://capi.flowe.io/Users/Details?token=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl

		return axios.get(  '/Rest/Teams?action=details&t=' + token + '&context=' + key )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	

	Getfiltered( token ){

		return axios.get(  '/Rest/Teams?action=getfiltered&t=' + token )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Getall( token , key ){

		return axios.get(  '/Rest/Teams?action=getall&t=' + token + '&context=' + key )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Getone( token , teamid ){

		return axios.get(  '/Rest/Teams?action=getone&t=' + token + '&teamid=' + teamid )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Select( token, key ){

		return axios.get(  '/Rest/Teams?action=select&t=' + token + '&context=' + key )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Update( payload ){

		/*
		"name": name ,
		"t": token
		*/

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Teams/?action=update', URLparams.toString() )
	},
	

	Createnew( payload ){

		/*
		"name": name ,
		"t": token
		*/


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Teams/?action=new', URLparams.toString() )
	},

	Createandjoin( token ){

		/*
		"name": name ,
		"t": token
		*/

		return axios.get(  '/Rest/Teams?action=joinnew&t=' + token )


	},

	Getusers( token, teamid ){

		/*
		"key": organization key ,
		"t": token
		*/

		return axios.get(  '/Rest/Teams?action=getusers&t=' + token + '&teamid=' + teamid )


	},


	Adduser( token, teamid, userid ){

		return axios.get(  '/Rest/Teams?action=addusertoteam&t=' + token + '&teamid=' + teamid + '&newvalue=' + userid )
	},

	Removeuser( token, teamid, userid, toconfirm, isforced, check ){

		let url = '/Rest/Teams?action=removeuserfromteam&t=' + token + '&teamid=' + teamid + '&newvalue=' + userid + "&confirm="+toconfirm
		
		if( isforced && isforced == true ){
			url += '&isforced=true'
		}

		if( check == 'checkstakeholders' ){
			url += '&checkstakeholders=true'
		}
		if( check == 'checkrisks' ){
			url += '&checkrisks=true'
		}
		if( check == 'checktracks' ){
			url += '&checktracks=true'
		}

		return axios.get(  url )
	},

	Setrole( token, teamid, userid, newrole, toconfirm ){

		console.log( token, teamid, userid, newrole, toconfirm, "zzzzzzz" )

		return axios.get(  '/Rest/Teams?action=setroleintheteam&t=' + token + '&teamid=' + teamid + '&newvalue=' + userid + '&newvalue2=' + newrole + "&confirm="+toconfirm )
	}






}

