var state = {
  userpool: "flowe-app-users",
  appdomain: "floweappusers.auth.eu-west-1.amazoncognito.com",
  clientid: "2lvmphbgdonovdgac461truu0s",
  redirecturi: "https://app.flowe.io/ssosignin",
  signouturi: "https://app.flowe.io/ssosignout",
  appurl: "https://app.flowe.io/"
}

//209fkelgjbps38j2qmb0n8jcfr
/*
function setLoggedIn(newValue) {
  state.loggedIn = newValue;
}

function setLoggedOut() {
  state.loggedIn = false;
  state.cognitoInfo = {};
}

function setCognitoInfo(newValue){
  state.cognitoInfo = newValue;
}*/

export default {
  state: state
}

/*

VUE_APP_COGNITO_USERPOOL_ID=flowe-federated-users
VUE_APP_COGNITO_APP_DOMAIN=floweusers.auth.eu-west-1.amazoncognito.com
VUE_APP_COGNITO_CLIENT_ID=4buvk9af4fjfcvhanv0uofp3q5

VUE_APP_COGNITO_REDIRECT_URI=http://localhost:8082/signin
VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT=http://localhost:8082/signout
VUE_APP_APP_URL=http://localhost:8080

*/