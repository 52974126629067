import Organizationsapi from '@/services/api/organizations'
import router from '@/router'
import Vue from 'vue'

import cookie from 'vue-cookie'

const organizations = {
 state:{
  listallorganizations:[],
  listorganizations:[],
  selectedorganizations:{},
  currentorganization:{},
  grouped: {
    administrator: [],
    member: [],
    disabled:[]
  }

  },
  mutations:{
    
    SET_LISTALLORGANIZATIONS(state, payload){
      Vue.set(state, "listallorganizations", payload)
    },

    SET_GROUPED(state, grouped){
      state.grouped = Object.assign({}, state.grouped, grouped)
    },

    SET_LISTORGANIZATIONS(state, payload){
      state.listorganizations = payload
    },

    SET_LIST_BY_KEY(state, payload){
      Vue.set(state.listorganizations, payload.key, Object.assign({} , state.listorganizations[payload.key], payload.content))
    },

    SET_SELECTORGANIZATIONS(state, payload){

      if( payload ){
        cookie.set('organizationkey', payload.key)
        state.selectedorganizations =  Object.assign({} , state.selectedorganizations, payload)
      }

    },

    SET_SELECTORGANIZATIONS_LOGO(state, payload){

      state.selectedorganizations.logo = payload.newvalue
      state.currentorganization.logo = payload.newvalue

    },

    SET_SELECTORGANIZATIONS_NAME(state, payload){

      state.selectedorganizations.name = payload.newvalue
      state.currentorganization.name = payload.newvalue

      var newlist = []
      for( var i in state.listorganizations ){


        if( state.listorganizations[i].key == payload.key ){
          state.listorganizations[i].name = payload.newvalue
        }

        newlist.push( state.listorganizations[i] )
      }

      state.listorganizations = newlist
      

    },
    SET_SELECTORGANIZATIONS_DESCRIPTION(state, payload){

      state.selectedorganizations.description = payload.newvalue
      //state.currentorganization.description = payload

    },

    SET_CURRENTORGANIZATION(state, payload){

      if( payload ){
        cookie.set('organizationkey', payload.key)
        state.currentorganization =  Object.assign({} , state.currentorganization, payload)
      }

    },

    SET_ORGANIZATIONS_BY_KEY(state, payload){
      Vue.set(state.selectedorganizations,payload.key, payload.content)
    },

    SET_ORGANIZATION_BY_OBJECT(state, payload){      
      state.selectedorganizations = Object.assign({} , state.organization, payload.content)
    },


  },
  actions:{

   /* presaveorganization(payload){

      console.log( payload)

      alert('')
    },*/
    setOrgmanually({commit},payload){
      //console.log( commit, payload, "setorg")

        commit('SET_SELECTORGANIZATIONS', payload)
        commit('SET_CURRENTORGANIZATION', payload)
    },

    fechtAllOrganizations({commit},payload){

      let token = payload.token
      //let key = payload.key
      
      return new Promise((resolve, reject) =>{

          Organizationsapi.Getall(token)
          .then(response =>{

            let organizations = response.data.organizations
            let grouped =  response.data.grouped
            commit('SET_LISTALLORGANIZATIONS', organizations)
            commit('SET_GROUPED', grouped)
            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },


    fechtOrganizations({commit},payload){
      let token = payload.token
      let key = payload.key
      
      return new Promise((resolve, reject) =>{

          Organizationsapi.Getfiltered(token)
          .then(response =>{

            let organizations = response.data.organizations
            commit('SET_LISTORGANIZATIONS', organizations)
            
            let defaultorganizations = {}
            if(key){

              //for( var i in organizations ){
              //  console.log( organizations[i].key, key )
             // }

              defaultorganizations = organizations.find(x => x.key === key);
              if(!defaultorganizations && organizations[0]){
               defaultorganizations = organizations[0]
//               router.push('/' +  organizations[0].key + '/boards')
              }

              //console.log( organizations, defaultorganizations, "zzz")
            }else{
             defaultorganizations = organizations.find(x => x.default === true);
  
            }

            commit('SET_SELECTORGANIZATIONS', defaultorganizations)
            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },

    fetchOrglist({commit},payload){

      //console.log( commit )
      let token = payload.token
      //let key = payload.key
      
      return new Promise((resolve, reject) =>{

          Organizationsapi.Getfiltered(token)
          .then(response =>{

            let organizations = response.data.organizations
            commit('SET_LISTORGANIZATIONS', organizations)

             resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },


    fechtOrganization({commit},payload){

      return new Promise((resolve, reject) =>{

          Organizationsapi.Details(payload.token, payload.key)
          .then(response =>{

            let organization = response.data.organization
            commit('SET_SELECTORGANIZATIONS', organization)

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },

    setCurrentOrg({commit,state},payload){


      return new Promise((resolve) =>{

        //console.log( state.listorganizations , "zzze")

        //state.listorganizations = {}

        //console.log('aa')
        var list = state.listorganizations

        var newlist = []
        for( var i in list ){

          if( list[i]._id ==  payload.organization._id ){
            newlist.unshift( list[i] )
          }else{
            newlist.push( list[i] )
          }
          
        }

        commit('SET_LISTORGANIZATIONS', newlist)
        commit('SET_SELECTORGANIZATIONS', payload.organization)

        commit('SET_CURRENTORGANIZATION', payload.organization)

        resolve(payload.organization)

      })

      

    },

    fechtOrganizationUsers({commit},payload){

      return new Promise((resolve, reject) =>{

          Organizationsapi.Getusers(payload.token, payload.key)
          .then(response =>{
            let users = response.data.users
            let usersarr =[]
            let userids = []
            for (var i in users){
              let newuser  = users[i].detail
              newuser.role = users[i].role
              newuser.userid = users[i].userid
              usersarr.push( newuser)
              userids.push(newuser.userid)
            }
            
            commit('SET_ORGANIZATIONS_BY_KEY', {key:'users', content:usersarr})
            commit('SET_ORGANIZATIONS_BY_KEY', {key:'usersids', content:userids})

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },

    updateOrganization({commit, getters},payload){  

     // alert( payload.newvalue )

      if( payload.field == 'logo' ){

        commit('SET_SELECTORGANIZATIONS_LOGO', { newvalue: payload.newvalue , key: payload.context} ) 

      }


      if( payload.field == 'name' ){

        commit('SET_SELECTORGANIZATIONS_NAME', { newvalue: payload.newvalue , key: payload.context} ) 

      }

      if( payload.field == 'description' ){

        commit('SET_SELECTORGANIZATIONS_DESCRIPTION', { newvalue: payload.newvalue , key: payload.context} ) 

      }    
   

      return new Promise((resolve, reject) =>{
          
          Organizationsapi.Update(payload)
          .then(response =>{

            let organization = response.data.organization
            let organizations = getters.organizations
              commit('SET_SELECTORGANIZATIONS', organization) 
              let indexorganization = organizations.findIndex(x => x.key === organization.key);
              commit('SET_LIST_BY_KEY', {key:indexorganization, content:organization})

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },

    selectOrganizations({commit},payload){

      let self = this

      return new Promise((resolve, reject) =>{

          Organizationsapi.Select(payload.token, payload.key)
          .then(response =>{
            if(response.data.ok){
                var org = response.data.organization

                //console.log( org ,"zzz")
                self.state.selectedorganizations = {}
                commit('SET_SELECTORGANIZATIONS', org)                 

               //this.fetchOrglist()

              
            }
            resolve(response)  


          }) 
          .catch(error => {console.log(error); router.push('404'); reject(error)})
          .finally( () => {
          })
      })
    },

    createOrganizations({commit},payload){

      return new Promise((resolve, reject) =>{

          Organizationsapi.Createnew(payload)
          .then(response =>{

          //  alert('xx')


            let organization = response.data.organization
                         
            commit('SET_SELECTORGANIZATIONS', organization)
            commit('SET_CURRENTORGANIZATION', organization)
            //router.push({})
            resolve(response)


          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    }
  },
  getters:{
    organizations: state => state.listorganizations,
    organizationKey: state => state.selectedorganizations.key,
    organization: state => state.selectedorganizations,
    currentorganization : state => state.currentorganization,
    harorgkey: state => key =>{
        var org =state.listorganizations
        var haskey = false  
        for (var i in org ){
          if (org[i].key == key){
            haskey = true
            break;
          }
        }
        return haskey;
    }

  },
  modules: {

  }
}


export default organizations