<template>

	<b-modal 
	id="newsletterviewer"
	v-model="isshown" 
	modal-class="modal-cockpit fullscreen-modal" 
	size="lg" 
	hide-footer no-fade 
	@hidden="modalhidden"
	@shown="modalshown">
	<!-- 	@hidden="modalhidden" 
	 -->
	<template v-slot:modal-header>
		<h3 class="modal-title">Newsletter</h3>
		<a class = "close-modal dialog-close-button">
      <i class="fal fa-times fa-lg " @click="isshown = false" ></i>
    </a>
	</template>

	<div  v-if ="pagemode =='idle'"  > 
		<i class="fa fa-circle-o-notch fa-spin"></i>
	</div> 

	<div v-else>


		<div class="newsletter-wrapper" >
			<div class =" newslettertitle w-100 p-3 N100-b">
				<h3 class=" mb-0 ">{{newsletter.name}} ({{newsletter.periodstart | timeFormat}} - {{newsletter.periodend | timeFormat}})</h3>
				<h4 class=" font-weight-bold" style="display: inline;"> {{cockpit.name}}</h4>
			</div>
		
		<div v-html="newsletter.contents" class =" newsletter-content pt-3 px-3 fr-view"></div>
		</div>
		<div class="comment-widgets">
		<div class="p-2 mt-2 text-left">
			<indicators :indicator="newsletter" :activeCockpit="cockpit.isactive" @showcomments="showcomments" @like="Postlike" :feedback="cockpit.isactive == true ? 'readwrite' : 'readonly'" :liketype="'newsletter'" :loading = "loadingindicatordata"></indicators>
		</div>

		<a :href="'#addcomments_'+newsletter._id" ref="gotocomments" target="_self">&nbsp;</a>

		<div id="newslettercomments" v-if="showalways">
		<comments v-if="newsletter" :placeholder="'Add a comment to the newsletter...'" :cockpit="cockpit" :activeObjective="true" :activeCockpit="cockpit.isactive" :loggedinuser="loggedinuser" :objectdata="newsletter" @onlike="Postlike" @replycomment="replycomment" @updated="updatepages" @oncommentposted="commentposted" @onupdatecomments="loadComments" :feedback="cockpit.isactive == true ? 'readwrite' : 'readonly'" :commenttype="'newsletter'" :showalways="true" ></comments>
		</div>



		</div>

	</div>

      </b-modal>
</template>

<style  lang="scss" >
@import '@/scss/abstracts/variable.scss';

</style>
<script type="text/javascript"></script>

<script>
	import Cockpitsapi from '@/services/api/cockpits';
	import Newslettersapi from '@/services/api/newsletters';
	import {indicators,comments} from '@/components/feedback';

	import moment from 'moment';

	import {bus} from '@/main';

	export default{
		name: 'newsletterviewer',
		props:{
			token:{
				type:String,
				default:()=>""
			},
			loggedinuser: Object,

		},
		data(){
			return{
				isshown: false,
				pagemode : "idle",
				newsletter :{},
				newsletterid:"",
				cockpit:{},
				cockpitid:'',
				comments:{},
				indicator : null,
				showalways: false,
				loadingindicatordata:{like:false, comments:true} 
			}
		},

		components:{indicators,comments},
		mounted(){

			
			bus.$off('onopennewsletterfromfeeds')
			bus.$on('onopennewsletterforviewing', this.opennewsletterforviewing )

		},
		
		filters: {},

		computed:{
			 orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },
		},

		methods:{
			replycomment(){},
			updatepages(id){

			},


			loadComments(newsletter){

				let self = this 
				Cockpitsapi.Comments( { context:this.orgkey, "iid":newsletter._id, "type":"newsletter", "token": this.token} )
				.then( function(response){

					if( response.data.ok && response.data.comments != null){
						//console.log( response.data.comments)
						self.newsletter.comments = response.data.comments
						self.newsletter.commentscount = response.data.numcomments

					}

				})
				.catch(error => console.log(error))
				.finally( () => {
					//do everything
					self.loadingindicatordata.comments =false
					//self.loadLikes( indicator )
				})

			},

			loadLikes(indicator){

			let self = this 
			Cockpitsapi.Likes( { "iid":indicator._id, "type":"status", "token": this.token } )
			.then( function(response){

				if( response.data.ok ){
				indicator.likescount = response.data.numlikes
				indicator.dislikescount = response.data.numdislikes
				indicator.vote = response.data.hasvoted
			}

			})
			.catch(error => console.log(error))
			.finally( () => {
				self.loadingindicatordata.like = false
			//do everything
			})


			},
			showcomments(){
				let self = this
				this.showalways = this.showalways ? false : true


				setTimeout( function(){

					//window.scrollByLines(5)

					//$('body').animate({ scrollTop: 2000 }, 'fast');


					//var top = document.getElementById('addcomments_61eea8191f6d49233b895688').offsetTop; //Getting Y of target element
    				//window.scrollTo(0, top);   

    				//var url = location.href;               //Save down the URL without hash.
	    			//location.href = "#addcomments_" + self.cockpitid;                 //Go to the target element.
	    			//history.replaceState(null,null,url);   //Don't like hashes. Changing it back

	    			

	    			self.$refs.gotocomments.click()

					// $(window).scrollTop(200)
				}, 100)
				
				
				//this.loadComments( newsletter )
			},

		

			modalhidden(){


				bus.$emit('updatednewsletterviewscount', this.newsletter._id )

				this.$func.removeQuery(['page','nid'],this.$route,this.$router)
				this.$emit('onmodalhidden')

			},

			modalshown(){
				


			},

			opennewsletterforviewing( newsletterid, cockpitid, countview ){

				//alert('vv')
				
				this.cockpitid = cockpitid
				this.newsletterid = newsletterid
				let self = this
				this.isshown = true
				Newslettersapi.Openforviewing( newsletterid, this.token, 'desktop', countview )
				.then( function(response){

				if( response.data.ok && response.data.newsletter ){ 

					self.newsletter = response.data.newsletter
					setTimeout( function(){
						document.title =self.newsletter.name+' | Flowe'
						
						self.loadComments( self.newsletter )


					}, 100)

					

					//bus.$emit('initcomments', self.newsletter)

					//console.log( self.newsletter, "newsletter")
					
					
						if(self.$route.nid != self.newsletter._id){
							self.$func.addQuery({nid:self.newsletter._id},self.$route, self.$router)
						}
						if(self.$route.page != 'newsletter'){
							self.$func.addQuery({page:'newsletter'},self.$route, self.$router)
						}
				}else{

					if( response.data.error && response.data.error.message == "nopermission" ){
						self.$router.push("/403")
					}else{
						self.$router.push("/400")
					}
					
				}

				}).finally(()=>{
		
				})

				Cockpitsapi.Cockpits( { "_id":this.cockpitid, "token": this.token } )
				.then( function(response){

					if( response.data.ok || !response.data.ok ){
					self.cockpit = response.data.cockpits
					}
				})
				.catch(error => console.log(error))

				.finally( () => {
					self.pagemode = 'ready'
				})


			},


		  closeallreplyboxes(){

	        for( var i in this.comments ){
	          this.comments[i].writingreply = false
	        }
	      },

		  commentposted( newsletter, comments, commenttype, iid, numcomments ){

		  	//self.$emit('oncommentposted', indicator, response.data.comments, commenttype, iid, response.data.numcomments )

		  	this.newsletter.commentscount = numcomments

		  	if( commenttype == 'newsletter' ){
		  		this.newsletter.comments = comments		  		
		  	}

		  	if( commenttype == 'comment' ){
		  		
		  		for( var i in this.newsletter.comments ){

		  			if( this.newsletter.comments[i]._id == iid ){
		  				this.newsletter.comments[i].comments = comments
		  			}		  			
	        	}
		  	}
		  	  bus.$emit('updatednewslettercommentscount',newsletter._id, numcomments)

		  },

		  Postlike(type, object , action){

		  	var iid = object.ID ? object.ID : object._id

 
	         var self = this
	 
	         Cockpitsapi.Issuealike( type, iid, action, this.token,this.cockpit._id )
	         .then( function(response){

	           if( response.data.ok ){

		            object.likescount = response.data.numlikes
		            object.dislikescount = response.data.numdislikes
		            object.vote = response.data.hasvoted
		            bus.$emit('updatednewsletterlikecount',iid,object.likescount, object.dislikescount)

	           }
	 
	        })
	         .catch(error => console.log(error))
	         .finally( () => {
	 
	               //do everything
	               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
	 
	             })
	 
	       },
		}
	}
</script>