import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

export default {

	Mydashboarditems( uuid , org ){
		return axios.get(  '/My/Dashboard/?t=' + uuid + "&context=" + org )
	},

	Uploadprofilepicture(imageformdata){
		return axios.post(  '/Tools/Doupload', imageformdata, { headers: { 'Content-Type': 'multipart/form-data' }  } )
	},

	Askgoogletoken( id_token ){
		return axios.get(  'https://www.googleapis.com/oauth2/v3/tokeninfo?id_token='+id_token )
	},

	Enablegooglesignin( aud, email, token ){

		return axios.get(  '/Users/Enablegooglesignin/?audvalue=' + aud+ '&emailvalue=' + email +"&t=" + token )
		//https://dapp.flowe.io
	}

}


