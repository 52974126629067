<script type="text/javascript"></script>
<template>
	<nav class="navbar-light bg-light bottom-navbar fixed-bottom " id="bottom-navbar" @click.prevent= "barclick($event)">
    <ul class="navbar-nav-ul mr-auto d-flex justify-content-around" style = " width: 100%">

      
        <template v-for ="item in items">
          <bottombaritem :item= "item" :key ="item.name" @onopenmenu="openmenu" />
        </template>

    </ul>

    <popupmenu  :shown = "showmenu" @onmodalhidden ="showmenu=false">

         <orgnazationsSelector class = "mb-3" v-on = "listeners" :userOrgItems  ="userorgitems" :orgkey = "orgkey" @close="closemenu"/>

    </popupmenu>
</nav>

</template>
<script type="text/javascript"></script>
<style lang="scss">
@import '@/scss/abstracts/variable.scss';
ul{
  list-style-type:none;
}
.logohedder{
  height:60px;
}

</style>
<script>
  import {bus} from '@/main'
  import popupmenu from'./popupmenu'
  import orgnazationsSelector from './orgnazationsSelector'
  import bottombaritem from "@/components/navs/bottombaritem"
  export default {
    name: 'BottomBar',
    props:{
      startscroll: false,
      myobjectivesid:String,
      route:String
    },
    data(){
     return{
      show:'',
      showmenu: false,
      prevScrollpos: window.pageYOffset,
      scrollCouldown: 20,
      canscroll: true,
      routes: window.location.hash,
      noselect:false,
    } 
  },
  mounted(){
    let self = this
    bus.$on('stopscroll', function(e){
      self.canscroll = e

      if(!e){
        window.removeEventListener('scroll', this.handleScroll);
       // console.log( window.jQuery("#bottom-navbar"))
     }

   })
  },
  components:{popupmenu, orgnazationsSelector, bottombaritem},
  wacth:{
  },

  computed:{
    items(){
      let boardlink = { path: this.orgname + '/boards'} 
      let newpath = this.$store.getters.getRouterByMeta("board", "navindex")
      if (newpath && 'board'.indexOf(this.$route.meta.navindex) <= -1  ){
        
          boardlink = newpath.path

      }

      return[
        {name: 'Boards', to:{ path: this.orgname + '/boards' }, active:'board', id:'myboard', link:true, icon:'far fa-border-all'},
        {name: 'Objectives', to:{ path: this.orgname + '/objectives' }, active:'objectives', id:'myobjetivesroute', link:true, icon:'far fa-dot-circle'},
        {name: 'Newsfeed', to:{ path: this.orgname + '/newsfeed' }, active:'newsfeed', id:'newsfeedroute', link:true, icon:'far fa-bell'},
        {name: 'Help', to:{ path:this.orgname + '/help' }, active:'help', id:'helproute', link:true, icon:'far fa-question-circle'},
        {name: 'Account', to:{ path: this.orgname + '/myaccount' }, active:'account organizations', id:'myaccount', link:false, icon:'far fa-user'},

        
      ]
    },
    selectedorg(){
        return  this.$store.state.organizations.currentorganization
      },
      
    userorgitems(){
      let org = this.$store.getters.organizations
      return org
    },
    orgname(){

        return "/"+this.$store.state.organizations.selectedorganizations.key

    },
     orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : this.$store.state.organizations.selectedorganizations.key
    },
    listeners(){

        return{
          ...this.$listeners,
        }
      }
  },
  methods:{
    barclick(event){
      if(this.showmenu){
        this.$root.$emit('bv::hide::modal', 'narbarmenu')
      }
    },

  toorgsettings(org){

    this.$root.$emit('bv::hide::modal', 'narbarmenu')
    this.$store.dispatch('setCurrentOrg',{ organization:org })
    this.$router.push( '/'+ org.key +'/organizations/about' )

  },

   signout(){
    let self = this
    this.$store.dispatch('doSignOut')
  },

  closemenu(){
    this.$root.$emit('bv::hide::modal', 'narbarmenu')
  },
  
  openmenu(ev, name){

    console.log( ev,name, "onopen")

    let self = this
    if(name == "Account"){
      setTimeout(function(){
        self.showmenu = true 
      },10)
    }
      
  },

  handleScroll(){

      var currentScrollPos = window.pageYOffset;
      this.scrollCouldown++;

      if(this.canscroll){

       if($(window).scrollTop() + $(window).height() == $(document).height()) {

        window.jQuery("#bottom-navbar").css({'bottom':'0'});
          //console.log('hello')

        } else if($(window).scrollTop() == 0) {

          window.jQuery("#bottom-navbar").css({'bottom':'0'});

        } else if (this.prevScrollpos > currentScrollPos && this.scrollCouldown > 3) {

          window.jQuery("#bottom-navbar").css({'bottom':'0'});

        } else if (this.scrollCouldown > 3) {

          window.jQuery("#bottom-navbar").css({'bottom': '-'+$('#bottom-navbar').height()+'px'});
          this.scrollCouldown = 0 

        }

        this.prevScrollpos = currentScrollPos;

      }else {
        window.jQuery("#bottom-navbar").css({'bottom':'0'});
      }
    },
  },

  updaded(){


  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {

  }
}

</script>