require('froala-editor/js/froala_editor.pkgd.min.js')

require('froala-editor/js/plugins/file.min.js')
require('froala-editor/js/plugins/image.min.js')
require('froala-editor/js/plugins/align.min.js')
require('froala-editor/js/plugins/colors.min.js')
require('froala-editor/js/plugins/paragraph_format.min.js')
require('froala-editor/js/plugins/lists.min.js')
require('froala-editor/js/plugins/link.min.js')
require('froala-editor/js/plugins/table.min.js')
require('froala-editor/js/plugins/emoticons.min.js')
require('froala-editor/css/plugins/table.min.css')
require('froala-editor/js/plugins/draggable.min.js')

require('froala-editor/js/plugins/url.min.js')


require('froala-editor/js/plugins/fullscreen.min.js')
require('froala-editor/css/plugins/fullscreen.min.css')

require('froala-editor/js/plugins/word_paste.min.js')

require('froala-editor/js/plugins/print.min.js')



require('froala-editor/js/plugins/special_characters.min.js')
require('froala-editor/css/plugins/special_characters.min.css')

require('froala-editor/js/plugins/help.min.js')



import FroalaEditor from 'froala-editor';

FroalaEditor.DefineIcon('rotateImg', {NAME: 'redo', SVG_KEY: 'redo'});
FroalaEditor.RegisterCommand('rotateImg', {
    title: 'Rotate',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback: function ( ) {

      var img = this.image.get();
      var src = img.attr('src')
      //var optionnow = 0
      //var origfilename = ""      
     
      var iss3 = src.indexOf('s3-eu-west-3.amazonaws.com/flowe-editor')
      
      
      if( iss3 > -1 ){
        
        var segments = src.split('/')

        //console.log( segments )
        var filename = segments.pop()


        //alert( img.attr('data-optionnow') )
        //optionnow = 
        //origfilename = img.attr('data-origfilename')

        //if( !origfilename ){
        //  origfilename = filename
        //}

        
        //alert( origfilename )

        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://imgproc.flowe.io/?i=' + filename, true);
        xhr.send();
        console.log(xhr, img, src, filename,  segments, iss3)
        xhr.onreadystatechange = function(){


          if(xhr.readyState === XMLHttpRequest.DONE) {
            var status = xhr.status;
            if (status === 0 || (status >= 200 && status < 400)) { 

              var data = JSON.parse(xhr.responseText)

              console.log( data)
              filename = data.filename

              var newsrc = 'https://s3-eu-west-3.amazonaws.com/flowe-editor/'+filename 
              //+ "?t=" + seed

              img.attr('src', "" )
              setTimeout( function(){
                img.attr('src', newsrc )
              }, 200)    
              //img.attr('data-origfilename', origfilename )


              /*if( optionnow == 6 ){
                optionnow = 0
              }else{
                optionnow = optionnow + 1
              }*/

              //alert( optionnow )

              //img.attr('data-optionnow', optionnow )
            }

              //console.log(xhr.responseText);
            } else {
              // Oh no! There has been an error with the request!
            }
          }

        //optionnow
        // https://imgproc.flowe.io/?o=4&i=281e9c015615addb28cb7790e6fb81ca.jpeg
        //https://profilepictures.flowe.io/?t=full&i=uploads1587410049267-uploads1582388157023-DSC_8993.JPG
        //Jquery.load( 'https://imgproc.flowe.io/?o=' + options[optionnow] + '&i=' + origfilename, function(){
        //  alert('')
        //})
        //img.attr('src', 'https://imgproc.flowe.io/?o=' + options[optionnow] + '&i=' + origfilename )
        

        /*setTimeout( function(){
          
        }, 200) */
        

        //1587655905825-281e9c015615addb28cb7790e6fb81ca.jpeg
        
        

      }
    

    }
  });

  //var siteurl = "https://deploy.flowe.io/static/img/"
  var siteurl = "http://app.flowe.io/static/img/"

  FroalaEditor.DefineIconTemplate('font_awesomegreen', '<i class="fas fa-[NAME] green"></i>');
  FroalaEditor.DefineIconTemplate('font_awesomered', '<i class="fas fa-[NAME] red"></i>');
  FroalaEditor.DefineIconTemplate('font_awesomeyellow', '<i class="fas fa-[NAME] yellow"></i>');
  FroalaEditor.DefineIconTemplate('font_awesomeblue', '<i class="fas fa-[NAME] blue"></i>');

  FroalaEditor.DefineIcon('insercheck', { NAME: 'check', template: 'font_awesomegreen' });
  FroalaEditor.RegisterCommand('insercheck', {
    title: 'Insert check',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'green.png">');
      this.undo.saveStep();
    }
  });
    
  FroalaEditor.DefineIcon('insertimes', { NAME: 'times', template: 'font_awesomered' });
  FroalaEditor.RegisterCommand('insertimes', {
    title: 'Insert cross',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'red.png">');
      this.undo.saveStep();
    }
  });

  FroalaEditor.DefineIcon('insertwarning', { NAME: 'exclamation', template: 'font_awesomeyellow' });
  FroalaEditor.RegisterCommand('insertwarning', {
    title: 'Insert warning',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'yellow.png">');
      this.undo.saveStep();
    }
  });

  FroalaEditor.DefineIcon('insertinformation', { NAME: 'info', template: 'font_awesomeblue' });
  FroalaEditor.RegisterCommand('insertinformation', {
    title: 'Insert information',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'blue.png">');
      this.undo.saveStep();
    }
  });