import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

export default {	

	Myobjectives( payload ){
		// console.log( payload._id, "payload")
		// payload
		//const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.get(  '/Rest/Objectives/?t='+payload.token+'&context='+payload.context)
		//return axios.get(  '/Rest/Cockpits/?get=' +payload._id + '&view=simple' + '&t=' + payload.token )

	},

	Riskchartdata( payload ){
		// console.log( payload._id, "payload")
		// payload
		//const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.get(  '/Rest/Riskchartdata/?get='+payload._id+'&view=simple&t='+payload.token+'&context='+payload.context)
		//return axios.get(  '/Rest/Cockpits/?get=' +payload._id + '&view=simple' + '&t=' + payload.token )

	},

	Cockpitschartdata( payload ){
		// console.log( payload._id, "payload")
		// payload
		//const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.get(  '/Rest/Cockpitschartdata/?get='+payload._id+'&view=simple&t='+payload.token)
		//return axios.get(  '/Rest/Cockpits/?get=' +payload._id + '&view=simple' + '&t=' + payload.token )

	},
	
	Cockpitspreload( payload ){
		// console.log( payload._id, "payload")
		// payload
		//const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.get(  '/Rest/Cockpitspreload/?get='+payload._id+'&view=simple&t='+payload.token)
		//return axios.get(  '/Rest/Cockpits/?get=' +payload._id + '&view=simple' + '&t=' + payload.token )

	},

	Cockpits( payload ){
		// console.log( payload._id, "payload")
		// payload
		//const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.get(  '/Rest/Cockpits/?get='+payload._id+'&view=simple&t='+payload.token)
		//return axios.get(  '/Rest/Cockpits/?get=' +payload._id + '&view=simple' + '&t=' + payload.token )

	},

	Objective( payload ){

		return axios.get(  '/Rest/Objective?mid=' + payload.iid + '&t='+ payload.token + '&context='+ payload.context )
	},

	Likes( payload ){
		// payload
		// .type status|comment
		// .iid id of parent
		// .t session token
		return axios.get( '/Rest/Likes?action=getlikes&liketype=' + payload.type + '&iid=' + payload.iid + '&t=' + payload.token )
	},

	Comments( payload ){

		// payload
		// .type status|comment
		// .iid id of parent
		// .token user token

		//console.log('https://capi.flowe.io/Rest/Comments?commenttype=status&action=getcomments&iid='+payload._id)		
		return axios.get(  '/Rest/Comments?context=' + payload.context + '&commenttype=' + payload.type + '&action=getcomments&iid='+payload.iid + '&t=' + payload.token)

	},

	Makeactive( payload ){

		var token =  localStorage.token

		// setto : yes|no
		// cockpitid : cockpit id
		//console.log('https://capi.flowe.io/Rest/Toactive?object=cockpit&setto=' + payload.setto + '&iid='+ payload.cid)

		return axios.get(  '/Rest/Toactive?object=' + payload.type + '&setto=' + payload.setto + '&iid='+ payload.cid + '&t=' + token )

	},

	Createnew( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Cockpit/Save/', URLparams.toString() )
	},

	Cockpitsave( payload ){

		//console.log( payload )

		var querystring = require('querystring');
		var payload2 = querystring.stringify(payload)

	

		//const URLparams = new URLSearchParams(Object.entries(payload))

		return axios.post(  '/Rest/Cockpitssave/?action=save', payload2 )

	},


	Cockpitedit( cockpitid, section, token, context ){

		// cockpitid : cockpit id
		return axios.get(  '/Rest/Cockpitspreload/?get=' + cockpitid + '&view=cockpitedit&section='+section + '&t=' + token + '&context='+context )

	},	

	Objectiveedit( objectiveid, token, context ){

		// objectiveid : objective id
		// token : user token

		return axios.get(  '/Rest/Measures/?get=' + objectiveid + '&view=measureedit&t=' + token + '&context='+context )

	},

	Objectivenew( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))

		return axios.post(  '/Measure/Save/?action=save', URLparams.toString())
	},

	Objectivesave( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))

		return axios.post(  '/Rest/Measuresave/?action=save', URLparams.toString())
	},

	Cockpitmembers( cockpitid, token, context ){

		//console.log('/Rest/Cockpitusers/?iid=' + cockpitid)

		return axios.get(  '/Rest/Cockpitusers/?iid=' + cockpitid + '&t=' + token + '&context=' +context  )
	},

	Objectivemembers( objectiveid, token, context ){

		//console.log('https://capi.flowe.io/Rest/Cockpitusers/?iid=' + cockpitid)
		return axios.get(  '/Rest/Measureusers?iid=' + objectiveid + '&t=' + token + '&context=' +context )
	},

	Objectiveindicatorsadd( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Indicators/Save', URLparams.toString() )
	},

	Objectiveindicatorsupdate( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Indicators/Update', URLparams.toString() )
	},


	Cockpitdescription( cockpitid ){
		return axios.get(  '/Rest/Fulldescription/?iid=' + cockpitid + '&type=cockpit'  )
	},

	Aggregatedindex( indicators, options, aggregation, token ){
		return axios.get(  '/Rest/Aggregatedindex?values=' + indicators + '&options=' + options + '&method=' + aggregation + '&t=' + token  )
	},

	Calculatedindex( t, r, i ){
		//https://dapp.flowe.io/Rest/Fulldescription/?iid=5db8e8241f6d4941f5b59425&type=cockpit
		return axios.get(  '/Indicators/Calculatedindex?t=' + t + '&r=' +r + '&usehigh=' + i  )
	},

	Issuealike( type, iid, isdislike, token, cockpitid ){

		/*payload

			type: status|comment = status if issuing a like to a status, 'comment' if issuing a like to a comment
			iid : id of the object it's liking
			isdisklike : true if issuing a dislike instead of like
			t : session token
			cockpitid : id of current cockpit
		*/
		return axios.get(  '/Rest/Likes?action=setlike&liketype=' +type+ '&iid=' + iid + '&isdislike=' + isdislike + '&t=' + token + '&cockpitid=' + cockpitid )
	},

	Postcomment( payload ){

		/* payload 

			"cockpitid": id of current cockpit
			"iid": id of object this is posting
			"commenttype": 'status|comment' 'status' if posting for an indicator 'comment' if posting as a reply
			"issilent": 'true|false' if user is anonymous or not
			"message": 'message text'
			"t": session token
			"action": "postcomment" // dont changed this

		*/

		//payload['message'] = payload['message'].replace(/</g,"&lt;")
		//console.log(payload['message'])
		//console.log(payload)
		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Comments', URLparams.toString() )
	},

	Getobjectives( query, excluded, token, context ){
		
		/*
			query : string of search
			exs : ids to be excluded from results
		*/
		var exs = []
		if( excluded && excluded.length > 0 ){
			exs = excluded.join("&exs[]=")
		}

		return axios.get(  '/Rest/Measures/?for=typeahead&context=' + context + '&user=mine&t='+token+'&q=' +query+ '&exs[]=' + exs )
	},

	Getusers( query, excluded, context, includeteams ){

		///Rest/Userslist?q=a&exs[]=5db8e8231f6d4941f5b593ff

		/*
			query : string of search
			exs : ids to be excluded from results
			context : org context
		*/
		var exs = []
		if( excluded && excluded.length > 0 ){
			exs = excluded.join("&exs[]=")
		}

		var teams = ""

		if( includeteams == true ){
			teams = "&teams=yes"
		}

		return axios.get(  '/Rest/Userslist/?q=' +query+ '&exs[]=' + exs + '&context='+context+teams )
	},

	Recalculateobjective( objectiveid, token ){

		return axios.get(  '/Rest/Measure?mid=' + objectiveid + '&recalculate=true&t='+token )
	},

	Verifylink( objectiveid, toid ){
		return axios.get( '/Rest/Canlink/?mid=' + toid + '&iid=' + objectiveid )
	},

	Objectiveview( objectiveid, token, context){
		//https://dapp.flowe.io/Rest/Measure?isfull=true&mid=5dbadbc81f6d49630977a6cc
		return axios.get( '/Rest/Measure?isfull=true&mid=' + objectiveid + '&t=' + token + '&context=' + context );	
	
	},

	Requestaccess( cockpitid, objectiveid, token, message){

		var payload = { "cockpitid":cockpitid, "iid":objectiveid, "t":token, "message":message }
		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Requestaccess/?to=measure', URLparams.toString() )
	},

	Sectiongetall( cockpitid, token){
		return axios.get( '/Rest/Cockpitsections?action=getall&cockpitid=' + cockpitid + '&t=' + token )
	},

	Sectionadd( cockpitid, token){
		return axios.get( '/Rest/Cockpitsections?action=add&cockpitid=' + cockpitid + '&t=' + token )
	},

	Sectionupdate( cockpitid, sectionid, field, value, token){
		//return axios.get( '/Rest/Cockpitsections?action=update&cockpitid=' +cockpitid+ '&sectionid=' +sectionid+ '&field='+ field +'&fieldvalue=' + value + '&t=' + token )

		var payload = { "cockpitid":cockpitid, "sectionid":sectionid, "field":field, "fieldvalue":value, "t":token }

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Cockpitsections?action=update', URLparams.toString() )

	},

	Sectionremove( cockpitid, sectionid, token){

		//console.log( cockpitid, sectionid, token ) 
		return axios.get( '/Rest/Cockpitsections?action=remove&sectionid=' + sectionid + '&cockpitid=' + cockpitid + '&t=' + token )
	},

	Sectionadditem( cockpitid, ids, sectionid, token){
		return axios.get( '/Rest/Cockpitsections?action=additemtosection&cockpitid=' + cockpitid + '&oids=' + ids + '&sectionid=' + sectionid + '&t=' + token )
	},

	Sectionremoveitem( cockpitid, id, sectionid, token){
		return axios.get( '/Rest/Cockpitsections?action=removeitemfromsection&cockpitid=' + cockpitid + '&objectiveid=' + id + '&sectionid=' + sectionid + '&t=' + token )
	},


	/*Statistics( cockpitid, token ){

		return axios.get( '/Rest/Cockpitstatistics?get='+cockpitid+'&t='+token)
	},*/

	Statistics( cockpitid, token ){

		return axios.get( '/Rest/Newsletterstatistics?get='+cockpitid+'&t='+token)
	},

	Objectivestatistics( objectiveid, token ){

		return axios.get( '/Rest/Objectivestatistics?get='+objectiveid+'&t='+token)
	},

	Tabclicked( indicatorid ){

		var token =  localStorage.token

		if( token ){
			return axios.get( '/Tools/Collector?title=indicator_tab_clicked&iid=' + indicatorid + '&action=open&t=' + token )
		}else{
			return false
		}
		
	},

	Autosave( id, content , token ){


		var payload = { "id":id, "content":content, "token":token }

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Autosave', URLparams.toString() )


	},

	Requestupgrade( payload ){

		//var payload = { "id":id, "content":content, "token":token }

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Requestupgrade', URLparams.toString() )

	},

	
	///Rest/Cockpitsections?action=update&cockpitid=5db8e8241f6d4941f5b59425&sectionid=5e37910ec5369bbd9fb43556&field=objectives&fieldvalue=[%225db8e8241f6d4941f5b59415%22,%225db8e8231f6d4941f5b59405%22,%225df01edd1f6d491d29977505%22]
	///Rest/Cockpitssave?field=measures&value=[%225db8e8231f6d4941f5b59402%22,%225db8e8241f6d4941f5b5941b%22,%225df6fbc01f6d4910c9f2ee40%22,%225de5979b1f6d4914414e08e0%22,%225db945191f6d497eaf09f
	
	//
	///Rest/Cockpitsections?action=removeitemfromsection&cockpitid=5db8e8241f6d4941f5b59425&oids=5de4bf8f1f6d4914414e08d2&sectionid=5e3791b1c5369bbd9fb4455c

}

